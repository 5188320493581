.link:hover{
  color: rgb(124, 144, 246) !important;
}

.link{
  text-decoration: none !important;
  color: #fff;
}

.banner-donation-footer {
  align-items: center;
  background-color: #62B6D0;
  display: flex;
  height: 120px;
  justify-content: center;
  position: relative;
}

.banner-donation-footer a, .banner-donation-footer p.banner-title {
  font-weight: 700;
  line-height: 3.2rem;
  position: relative;
  z-index: 1;
}

.banner-donation-footer p.banner-title {
  color: #fff;
  font-size: 1.8rem;
  /* margin-bottom: 0; */
  margin-bottom: 0;
  margin-right: 40px;
  margin-top: 0;
}

.banner-donation-footer a {
  background-color: #F1B9D0;
  border-radius: 40px;
  color: #ffff;
  font-size: 1.5rem;
  padding: 8px 80px;
  /* text-transform: uppercase; */
  text-decoration: none;
}

@media screen and (min-width: 1280px) {
  p.banner-title {
    font-size: 4.8rem;
    line-height: 5.6rem;
    margin-bottom: 4.8rem;
  }
}

@media screen and (min-width: 768px) {
  p.banner-title {
    font-size: 4.2rem;
    line-height: 5.2rem;
  }
}