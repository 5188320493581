body {
  overflow: scroll;
}

.bubbleBackground {
    text-align: center;
    background:#000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -10;
}
.phaction-logo {
    position: relative;
    color: #fff;
}
.bubbleImg {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    width: 250px;
    border-top: 0;
    z-index: 3;
}

.bubble-shadow {
    width: 210px;
    height: 210px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    z-index: 1;
    box-shadow: 10px -30px 30px 14px #0A192D, 12px -20px 95px 20px #5065A6, 5px -25px 15px 7px #62B6D0, 24px -10px 47px 10px #24539b, -10px -12px 45px 5px #0B1A30, 25px 2px 8px 5px #4b67f2, 3px 2px 30px 5px #495fcd;
    animation: shadow-rotate 1.5s linear infinite;
    transform-origin: center;
}

@keyframes shadow-rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
   }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
   }
}

.bubbleBeat {
	font-size: 150px;
	animation: beat 1.20s infinite alternate;
	transform-origin: center;
}

.bubbleBeatShadow {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    z-index: 1;
    box-shadow: 10px 10px 47px 10px #24539b;
    animation: shadow-rotate 1.5s linear infinite;
    transform-origin: center;
}

/* Heart beat animation */
@keyframes beat{
	to { transform: scale(1.2); }
}

.bubbleGlowBeatShadow {
    width: 200px;
    height: 200px;
    box-shadow: 0 0 40px #24539b;
    position: absolute;
    border-radius: 100%;
    z-index: 1;
    animation: beatGlow 1.0s infinite alternate;
    transform-origin: center;
}

.bubbleGlowBeatShadow2 {
    width: 200px;
    height: 200px;
    box-shadow: 0 0 40px #62B6D0;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    z-index: 1;
    animation: beatGlow2 1.0s infinite alternate;
    transform-origin: center;
}

.app-container {
    visibility: hidden;
}


.introAnimation {
    animation-name: introAnimation;
    width: 100%;
    visibility: visible;
    animation: fadeIn 1.3s ease-in-out forwards;
}

.removeAnimation {
    animation: fadeOut 1.2s ease-out forwards;
    -moz-animation: fadeOut 1.2s ease-out forwards;
}

@keyframes beatGlow {
    to { transform: scale(1.2) }
}

@keyframes beatGlow2 {
    to { transform: scale(0.95) }
}

@keyframes slideIn {
    0% {
        transform: translateX(800px) scale(1);
    }
    100% {
        transform: translateX(0px) scale(1);
    }
}

@keyframes fadeOut {
    0% { opacity: 1; visibility: visible; } 
    25% { opacity: 0.75; visibility: visible; } 
    50% { opacity: 0.50; visibility: visible; } 
    75% { opacity: 0.25; visibility: visible; } 
    100% { opacity: 0; visibility: hidden; }
}

@-moz-keyframes fadeOut {
    0% { opacity: 1; visibility: visible; } 
    25% { opacity: 0.75; visibility: visible; } 
    50% { opacity: 0.50; visibility: visible; } 
    75% { opacity: 0.25; visibility: visible; } 
    100% { opacity: 0; visibility: hidden; }
}

/* @media screen and (min-width: 1120px) { */
    /* body {
        padding-top: 120px !important;
    } */
/* } */