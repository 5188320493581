.cl-section-row {
    margin-left: auto;
    margin-right: auto;
    min-width: 0;
}

.cl-section-row-content-wrap {
    position: relative;
}

/* .cl-section-row > .cl-section-row-content-wrap {
    padding-top: 214px;
    padding-bottom: 214px;
} */

.cl-section-row-content-wrap {
    padding-top: 32px;
    /* padding-right: 32px; */
    padding-bottom: 32px;
    /* padding-left: 32px; */
}

.cl-section-row-content-wrap {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.cl-section-row-content-wrap {
    position: relative;
}

.cl-section-row-content-wrap .top-right-badge {
    position: absolute;
    z-index: 2;
}

.cl-section-row-content-wrap .top-right-badge {
    right: 10px;
    /* top: -180px; */
    width: 400px;
}

.cl-section-row-content-wrap .bottom-left-badge {
    /* bottom: -180px; */
    left: 10px;
    width: 350px;
}

.cl-section-row-content-wrap .bottom-left-badge {
    position: absolute;
    z-index: 2;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 0ms;
}

@media screen and (min-width: 1600px) {
    .cl-section-row-content-wrap .top-right-badge {
        top: 0;
    }
}

@media screen and (min-width: 1400px) {
    .cl-section-row-content-wrap .top-right-badge {
        top: -50px;
        width: 336px;
    }
}

@media screen and (min-width: 1280px) {
    .cl-section-row-content-wrap .top-right-badge {
        top: -20px;
        width: 336px;
    }
}

@media screen and (min-width: 1050px) {
    .cl-section-row-content-wrap .top-right-badge {
        /* top: -50px; */
        width: 270px;
    }
}

@media screen and (min-width: 1200px) {
    .cl-section-row-content-wrap .top-right-badge {
        top: 0;
        width: 550px;
        right:7.14vw;   
     }
}

@media screen and (max-width: 1200px) {
    .cl-section-row-content-wrap .top-right-badge {
        top: 0;
        width: 48vw;
        right:2.14vw;
    }
}

@media screen and (max-width: 1050px) {
    .cl-section-row-content-wrap .top-right-badge {
        top: 80px;
        
    }
}
@media screen and (max-width: 900px) {
    .cl-section-row-content-wrap .top-right-badge {
       display: none;
    }
    .cl-section-row-content-wrap .bottom-left-badge{
        display: none;
     }
}

@media screen and (min-width: 1600px) {
    .cl-section-row-content-wrap .bottom-left-badge {
        bottom: 0;
    }
}

@media screen and (min-width: 1400px) {
    .cl-section-row-content-wrap .bottom-left-badge {
        bottom: -50px;
        width: 336px;
    }
}

@media screen and (min-width: 1280px) {
    .cl-section-row-content-wrap .bottom-left-badge {
        bottom: -80px;
        width: 336px;
    }
}

@media screen and (min-width: 1050px) {
    .cl-section-row-content-wrap .bottom-left-badge {
        bottom: -50px;
        width: 290px;
    }
}

@media screen and (min-width: 1200px) {
    .cl-section-row-content-wrap .bottom-left-badge {
        bottom: 1vw;
        width: 550px;
        left:8.14vw;
    }
}

@media screen and (max-width: 1200px) {
    .cl-section-row-content-wrap .bottom-left-badge {
        /* bottom: 1vw; */
        width: 48vw;
        left:2.14vw;
    }
}