#receipt { 
  width: 210mm; 
  height: 297mm; 
}

@media screen and (max-width: 900px) {
  #receipt { 
    width: auto; 
    height: auto; 
  }
}