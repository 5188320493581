.animated-scroll-hero-parent {
    height: 100vh;
    position: relative;
    width: 100vw;
}

body .animated-scroll-hero-parent {
    margin-top: -60px;
}

/* @media screen and (min-width: 1120px) {
    body .animated-scroll-hero-parent {
        margin-top: -120px;
    }
} */

.fl-row, .fl-row-content {
    margin-left: auto;
    margin-right: auto;
    min-width: 0;
}

.fl-node-b.fl-row-full-height > .fl-row-content-wrap {
    height: 100%;
}

.fl-row.fl-row-full-width.fl-row-bg-video.fl-node-b.fl-row-full-height.fl-row-align-center.homepage-video.animated-scroll-hero-parent{
    height:100%;
    min-height: 100%;
    /* transform: translate(0px, 0px) !important; */
}
.fl-row.fl-row-full-width.fl-row-bg-video.fl-node-b.fl-row-full-height.fl-row-align-center.homepage-video.animated-scroll-hero-parent::after{
    transform: translate(0px, 0px) !important;
}
.fl-node-b.fl-row-full-height > .fl-row-content-wrap, .fl-node-b.fl-row-custom-height > .fl-row-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.fl-row-full-height.fl-row-align-center .fl-row-content-wrap, .fl-row-custom-height.fl-row-align-center .fl-row-content-wrap {
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.fl-row-content-wrap {
    padding:0,
    /* padding-top: 32px; */
    /* padding-right: 32px; */
    /* padding-bottom: 32px; */
    /* padding-left: 32px; */
}

.fl-row-content-wrap {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.fl-row-content-wrap {
    position: relative;
}

.fl-row-bg-video, .fl-row-bg-video .fl-row-content, .fl-row-bg-embed, .fl-row-bg-embed .fl-row-content {
    position: relative;
}

.fl-row-bg-video .fl-bg-video iframe, .fl-row-bg-embed .fl-bg-embed-code iframe {
    pointer-events: none;
    width: 100vw;
    height: 56.25vw;
    max-width: none;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.hero-pinspacer.pin-spacer{
    overflow: hidden !important;
}
.fl-row.fl-row-full-width.fl-row-bg-video.fl-node-b.fl-row-full-height.fl-row-align-center.homepage-video.animated-scroll-hero-parent{
    top: 0 !important;
}

.fl-row-bg-video .fl-bg-video, .fl-row-bg-embed .fl-bg-embed-code {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
}

/* .fl-row-fixed-width {
    max-width: 1280px;
} */

.fl-row-full-height .fl-row-content, .fl-row-custom-height .fl-row-content {
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.animated-scroll-hero-parent #svg {
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(1);
    width: 100%;
    z-index: 20;
}


.animated-scroll-hero-parent .animated-hero {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.animated-scroll-hero-parent .overlay {
    align-items: flex-end;
    background: hsla(0,0%,7%,.8);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    /* padding-bottom: 3.4rem; */
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 10;
}

.animated-scroll-hero-parent .overlay .text {
    color: #fff;
    display: inline-block;
    font-size: 3.0rem;
    font-weight: 700;
    line-height: 4.5rem;
    /* max-width: 400px; */
    text-align: center;
    text-transform: uppercase;
    width: 90%;
}

@media screen and (max-width: 768px) {
    .animated-scroll-hero-parent .overlay .text {
        color: #fff;
        display: inline-block;
        font-size: 2.0rem;
        font-weight: 700;
        line-height: 3rem;
        /* max-width: 400px; */
        text-align: center;
        text-transform: uppercase;
        width: 90%;
    }
}

.animated-scroll-hero-parent .overlay .text span {
    display: block;
    /* margin-bottom: 3.4rem; */
}

.lazyloaded {
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 0ms;
}

@media screen and (min-width: 768px) {
    .animated-scroll-hero-parent .overlay .text {
        font-size: 2.0rem;
        line-height: 4.6rem;
        max-width: 600px;
    }
}


@media screen and (min-width: 768px) {
    .animated-scroll-hero-parent .overlay .text span {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 1280px) {
    .animated-hero .overlay .text {
        font-size: 3.0rem;
        line-height: 4.4rem;
        max-width: 800px;
    }
}

@media screen and (min-width: 1280px) {
    .animated-hero .overlay .text span {
        margin-bottom: 20px;
    }
}


