.fl-builder-content *,.fl-builder-content *:before,.fl-builder-content *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fl-row:before,.fl-row:after,.fl-row-content:before,.fl-row-content:after,.fl-col-group:before,.fl-col-group:after,.fl-col:before,.fl-col:after,.fl-module:before,.fl-module:after,.fl-module-content:before,.fl-module-content:after {
    display: table;
    content: " ";
}

.fl-row:after,.fl-row-content:after,.fl-col-group:after,.fl-col:after,.fl-module:after,.fl-module-content:after {
    clear: both;
}

.fl-clear {
    clear: both;
}

.fl-clearfix:before,.fl-clearfix:after {
    display: table;
    content: " ";
}

.fl-clearfix:after {
    clear: both;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.fl-visible-large,.fl-visible-large-medium,.fl-visible-medium,.fl-visible-medium-mobile,.fl-visible-mobile,.fl-col-group .fl-visible-medium.fl-col,.fl-col-group .fl-visible-medium-mobile.fl-col,.fl-col-group .fl-visible-mobile.fl-col {
    display: none;
}

.fl-row,.fl-row-content {
    margin-left: auto;
    margin-right: auto;
    min-width: 0;
}

.fl-row-content-wrap {
    position: relative;
}

.fl-builder-mobile .fl-row-bg-photo .fl-row-content-wrap {
    background-attachment: scroll;
}

.fl-row-bg-video,.fl-row-bg-video .fl-row-content,.fl-row-bg-embed,.fl-row-bg-embed .fl-row-content {
    position: relative;
}

.fl-row-bg-video .fl-bg-video,.fl-row-bg-embed .fl-bg-embed-code {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
}

.fl-row-bg-video .fl-bg-video video,.fl-row-bg-embed .fl-bg-embed-code video {
    bottom: 0;
    left: 0px;
    max-width: none;
    position: absolute;
    right: 0;
    top: 0px;
}

.fl-row-bg-video .fl-bg-video iframe,.fl-row-bg-embed .fl-bg-embed-code iframe {
    pointer-events: none;
    width: 100vw;
    height: 56.25vw;
    max-width: none;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.fl-bg-video-fallback {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0px;
    left: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
}

.fl-row-bg-slideshow,.fl-row-bg-slideshow .fl-row-content {
    position: relative;
}

.fl-row .fl-bg-slideshow {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.fl-builder-edit .fl-row .fl-bg-slideshow * {
    bottom: 0;
    height: auto !important;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
}

.fl-row-bg-overlay .fl-row-content-wrap:after {
    border-radius: inherit;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.fl-row-bg-overlay .fl-row-content {
    position: relative;
    z-index: 1;
}

.fl-row-custom-height .fl-row-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
}

.fl-row-overlap-top .fl-row-content-wrap {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
}

.fl-row-custom-height .fl-row-content-wrap {
    min-height: 0;
}

.fl-row-full-height .fl-row-content,.fl-row-custom-height .fl-row-content {
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.fl-row-full-height .fl-row-full-width.fl-row-content,.fl-row-custom-height .fl-row-full-width.fl-row-content {
    max-width: 100%;
    width: 100%;
}

.fl-builder-ie-11 .fl-row.fl-row-full-height:not(.fl-visible-medium):not(.fl-visible-medium-mobile):not(.fl-visible-mobile),.fl-builder-ie-11 .fl-row.fl-row-custom-height:not(.fl-visible-medium):not(.fl-visible-medium-mobile):not(.fl-visible-mobile) {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fl-builder-ie-11 .fl-row-full-height .fl-row-content-wrap,.fl-builder-ie-11 .fl-row-custom-height .fl-row-content-wrap {
    height: auto;
}

.fl-builder-ie-11 .fl-row-full-height .fl-row-content,.fl-builder-ie-11 .fl-row-custom-height .fl-row-content {
    flex: 0 0 auto;
    flex-basis: 100%;
    margin: 0;
}

.fl-builder-ie-11 .fl-row-full-height.fl-row-align-top .fl-row-content,.fl-builder-ie-11 .fl-row-full-height.fl-row-align-bottom .fl-row-content,.fl-builder-ie-11 .fl-row-custom-height.fl-row-align-top .fl-row-content,.fl-builder-ie-11 .fl-row-custom-height.fl-row-align-bottom .fl-row-content {
    margin: 0 auto;
}

.fl-builder-ie-11 .fl-row-full-height.fl-row-align-center .fl-col-group:not(.fl-col-group-equal-height),.fl-builder-ie-11 .fl-row-custom-height.fl-row-align-center .fl-col-group:not(.fl-col-group-equal-height) {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.fl-row-full-height.fl-row-align-center .fl-row-content-wrap,.fl-row-custom-height.fl-row-align-center .fl-row-content-wrap {
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.fl-row-full-height.fl-row-align-bottom .fl-row-content-wrap,.fl-row-custom-height.fl-row-align-bottom .fl-row-content-wrap {
    align-items: flex-end;
    justify-content: flex-end;
    -webkit-align-items: flex-end;
    -webkit-justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-box-pack: end;
    -ms-flex-align: end;
    -ms-flex-pack: end;
}

.fl-builder-ie-11 .fl-row-full-height.fl-row-align-bottom .fl-row-content-wrap,.fl-builder-ie-11 .fl-row-custom-height.fl-row-align-bottom .fl-row-content-wrap {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.fl-col-group-equal-height,.fl-col-group-equal-height .fl-col,.fl-col-group-equal-height .fl-col-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.fl-col-group-equal-height {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}

.fl-col-group-equal-height.fl-col-group-has-child-loading {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.fl-col-group-equal-height .fl-col,.fl-col-group-equal-height .fl-col-content {
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.fl-col-group-equal-height .fl-col-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    flex-shrink: 1;
    min-width: 1px;
    max-width: 100%;
    width: 100%;
}

.fl-col-group-equal-height:before,.fl-col-group-equal-height .fl-col:before,.fl-col-group-equal-height .fl-col-content:before,.fl-col-group-equal-height:after,.fl-col-group-equal-height .fl-col:after,.fl-col-group-equal-height .fl-col-content:after {
    content: none;
}

.fl-col-group-nested.fl-col-group-equal-height.fl-col-group-align-top .fl-col-content,.fl-col-group-equal-height.fl-col-group-align-top .fl-col-content {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.fl-col-group-nested.fl-col-group-equal-height.fl-col-group-align-center .fl-col-content,.fl-col-group-equal-height.fl-col-group-align-center .fl-col-content {
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.fl-col-group-nested.fl-col-group-equal-height.fl-col-group-align-bottom .fl-col-content,.fl-col-group-equal-height.fl-col-group-align-bottom .fl-col-content {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
}

.fl-col-group-equal-height.fl-col-group-align-center .fl-module,.fl-col-group-equal-height.fl-col-group-align-center .fl-col-group {
    width: 100%;
}

.fl-builder-ie-11 .fl-col-group-equal-height,.fl-builder-ie-11 .fl-col-group-equal-height .fl-col,.fl-builder-ie-11 .fl-col-group-equal-height .fl-col-content,.fl-builder-ie-11 .fl-col-group-equal-height .fl-module,.fl-col-group-equal-height.fl-col-group-align-center .fl-col-group {
    min-height: 1px;
}

.fl-col {
    float: left;
    min-height: 1px;
}

.fl-col-bg-overlay .fl-col-content {
    position: relative;
}

.fl-col-bg-overlay .fl-col-content:after {
    border-radius: inherit;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.fl-col-bg-overlay .fl-module {
    position: relative;
    z-index: 2;
}

.fl-module img {
    max-width: 100%;
}

.fl-builder-module-template {
    margin: 0 auto;
    max-width: 1100px;
    padding: 20px;
}

.fl-builder-content a.fl-button,.fl-builder-content a.fl-button:visited {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    padding: 12px 24px;
    text-decoration: none;
    text-shadow: none;
}

.fl-builder-content .fl-button:hover {
    text-decoration: none;
}

.fl-builder-content .fl-button:active {
    position: relative;
    top: 1px;
}

.fl-builder-content .fl-button-width-full .fl-button {
    display: block;
    text-align: center;
}

.fl-builder-content .fl-button-width-custom .fl-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.fl-builder-content .fl-button-left {
    text-align: left;
}

.fl-builder-content .fl-button-center {
    text-align: center;
}

.fl-builder-content .fl-button-right {
    text-align: right;
}

.fl-builder-content .fl-button i {
    font-size: 1.3em;
    height: auto;
    margin-right: 8px;
    vertical-align: middle;
    width: auto;
}

.fl-builder-content .fl-button i.fl-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.fl-builder-content .fl-button-has-icon .fl-button-text {
    vertical-align: middle;
}

.fl-icon-wrap {
    display: inline-block;
}

.fl-icon {
    display: table-cell;
    vertical-align: middle;
}

.fl-icon a {
    text-decoration: none;
}

.fl-icon i {
    float: right;
    height: auto;
    width: auto;
}

.fl-icon i:before {
    border: none !important;
    height: auto;
    width: auto;
}

.fl-icon-text {
    display: table-cell;
    text-align: left;
    padding-left: 15px;
    vertical-align: middle;
}

.fl-icon-text-empty {
    display: none;
}

.fl-icon-text *:last-child {
    margin: 0 !important;
    padding: 0 !important;
}

.fl-icon-text a {
    text-decoration: none;
}

.fl-icon-text span {
    display: block;
}

.fl-icon-text span.mce-edit-focus {
    min-width: 1px;
}

.fl-photo {
    line-height: 0;
    position: relative;
}

.fl-photo-align-left {
    text-align: left;
}

.fl-photo-align-center {
    text-align: center;
}

.fl-photo-align-right {
    text-align: right;
}

.fl-photo-content {
    display: inline-block;
    line-height: 0;
    position: relative;
    max-width: 100%;
}

.fl-photo-img-svg {
    width: 100%;
}

.fl-photo-content img {
    display: inline;
    height: auto;
    max-width: 100%;
}

.fl-photo-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fl-photo-caption {
    font-size: 13px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fl-photo-caption-below {
    padding-bottom: 20px;
    padding-top: 10px;
}

.fl-photo-caption-hover {
    background: rgba(0,0,0,0.7);
    bottom: 0;
    color: #fff;
    left: 0;
    opacity: 0;
    filter: alpha(opacity = 0);
    padding: 10px 15px;
    position: absolute;
    right: 0;
    -webkit-transition: opacity 0.3s ease-in;
    -moz-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
}

.fl-photo-content:hover .fl-photo-caption-hover {
    opacity: 100;
    filter: alpha(opacity = 100);
}

.fl-builder-pagination,.fl-builder-pagination-load-more {
    padding: 40px 0;
}

.fl-builder-pagination ul.page-numbers {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.fl-builder-pagination li {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.fl-builder-pagination li a.page-numbers,.fl-builder-pagination li span.page-numbers {
    border: 1px solid #e6e6e6;
    display: inline-block;
    padding: 5px 10px;
    margin: 0 0 5px;
}

.fl-builder-pagination li a.page-numbers:hover,.fl-builder-pagination li span.current {
    background: #f5f5f5;
    text-decoration: none;
}

.fl-slideshow,.fl-slideshow * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.fl-slideshow .fl-slideshow-image img {
    max-width: none !important;
}

.fl-slideshow-social {
    line-height: 0 !important;
}

.fl-slideshow-social * {
    margin: 0 !important;
}

.fl-builder-content .bx-wrapper .bx-viewport {
    background: transparent;
    border: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    left: 0;
}

.mfp-wrap button.mfp-arrow,.mfp-wrap button.mfp-arrow:active,.mfp-wrap button.mfp-arrow:hover,.mfp-wrap button.mfp-arrow:focus {
    background: transparent !important;
    border: none !important;
    outline: none;
    position: absolute;
    top: 50%;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.mfp-wrap .mfp-close,.mfp-wrap .mfp-close:active,.mfp-wrap .mfp-close:hover,.mfp-wrap .mfp-close:focus {
    background: transparent !important;
    border: none !important;
    outline: none;
    position: absolute;
    top: 0;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.admin-bar .mfp-wrap .mfp-close,.admin-bar .mfp-wrap .mfp-close:active,.admin-bar .mfp-wrap .mfp-close:hover,.admin-bar .mfp-wrap .mfp-close:focus {
    top: 32px!important;
}

img.mfp-img {
    padding: 0;
}

.mfp-counter {
    display: none;
}

.mfp-wrap .mfp-preloader.fa {
    font-size: 30px;
}

.fl-form-field {
    margin-bottom: 15px;
}

.fl-form-field input.fl-form-error {
    border-color: #DD6420;
}

.fl-form-error-message {
    clear: both;
    color: #DD6420;
    display: none;
    padding-top: 8px;
    font-size: 12px;
    font-weight: lighter;
}

.fl-form-button-disabled {
    opacity: 0.5;
}

.fl-animation {
    opacity: 0;
}

.fl-builder-preview .fl-animation,.fl-builder-edit .fl-animation,.fl-animated {
    opacity: 1;
}

.fl-animated {
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

.fl-button.fl-button-icon-animation i.fl-button-icon-after {
    margin-left: 0px !important;
}

.fl-button.fl-button-icon-animation:hover i.fl-button-icon-after {
    margin-left: 10px !important;
}

.fl-button.fl-button-icon-animation i.fl-button-icon-before {
    margin-right: 0 !important;
}

.fl-button.fl-button-icon-animation:hover i.fl-button-icon-before {
    margin-right: 20px !important;
    margin-left: -10px;
}

.single:not(.woocommerce).single-fl-builder-template .fl-content {
    width: 100%;
}

.fl-builder-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
}

.fl-builder-shape-layer {
    z-index: 0;
}

.fl-builder-shape-layer.fl-builder-bottom-edge-layer {
    z-index: 1;
}

.fl-row-bg-overlay .fl-builder-shape-layer {
    z-index: 1;
}

.fl-row-bg-overlay .fl-builder-shape-layer.fl-builder-bottom-edge-layer {
    z-index: 2;
}

.fl-row-has-layers .fl-row-content {
    z-index: 1;
}

.fl-row-bg-overlay .fl-row-content {
    z-index: 2;
}

.fl-builder-layer > * {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.fl-builder-layer + .fl-row-content {
    position: relative;
}

.fl-builder-layer .fl-shape {
    fill: #aaa;
    stroke: none;
    stroke-width: 0;
    width: 100%;
}

@supports (-webkit-touch-callout: inherit) {
    .fl-row.fl-row-bg-parallax .fl-row-content-wrap,.fl-row.fl-row-bg-fixed .fl-row-content-wrap {
        background-position: center !important;
        background-attachment: scroll !important;
    }
}

@supports (-webkit-touch-callout: none) {
    .fl-row.fl-row-bg-fixed .fl-row-content-wrap {
        background-position: center !important;
        background-attachment: scroll !important;
    }
}

/* .fl-row-fixed-width {
    max-width: 85vw;
} */

.fl-row-content-wrap {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

/* .fl-row-content-wrap {
    padding-top: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
} */

.fl-col-content {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.fl-col-content {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.fl-module-content {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

@media (max-width: 1200px) {
    body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-xl,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-xl {
        display: none !important;
    }

    .fl-visible-xl,.fl-visible-medium,.fl-visible-medium-mobile,.fl-visible-mobile,.fl-col-group .fl-visible-xl.fl-col,.fl-col-group .fl-visible-medium.fl-col,.fl-col-group .fl-visible-medium-mobile.fl-col,.fl-col-group .fl-visible-mobile.fl-col {
        display: none;
    }

    .fl-visible-desktop,.fl-visible-desktop-medium,.fl-visible-large,.fl-visible-large-medium,.fl-col-group .fl-visible-desktop.fl-col,.fl-col-group .fl-visible-desktop-medium.fl-col,.fl-col-group .fl-visible-large.fl-col,.fl-col-group .fl-visible-large-medium.fl-col {
        display: block;
    }

    .fl-col-group-equal-height .fl-visible-desktop.fl-col,.fl-col-group-equal-height .fl-visible-desktop-medium.fl-col,.fl-col-group-equal-height .fl-visible-large.fl-col,.fl-col-group-equal-height .fl-visible-large-medium.fl-col {
        display: flex;
    }
}

@media (max-width: 1100px) {
    body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-xl,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-xl,body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-desktop,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-desktop,body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-large,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-large {
        display: none !important;
    }

    .fl-visible-xl,.fl-visible-desktop,.fl-visible-large,.fl-visible-mobile,.fl-col-group .fl-visible-xl.fl-col,.fl-col-group .fl-visible-desktop.fl-col,.fl-col-group .fl-visible-large.fl-col,.fl-col-group .fl-visible-mobile.fl-col {
        display: none;
    }

    .fl-visible-desktop-medium,.fl-visible-large-medium,.fl-visible-medium,.fl-visible-medium-mobile,.fl-col-group .fl-visible-desktop-medium.fl-col,.fl-col-group .fl-visible-large-medium.fl-col,.fl-col-group .fl-visible-medium.fl-col,.fl-col-group .fl-visible-medium-mobile.fl-col {
        display: block;
    }

    .fl-col-group-equal-height .fl-visible-desktop-medium.fl-col,.fl-col-group-equal-height .fl-visible-large-medium.fl-col,.fl-col-group-equal-height .fl-visible-medium.fl-col,.fl-col-group-equal-height .fl-visible-medium-mobile.fl-col {
        display: flex;
    }

    .fl-row[data-node] > .fl-row-content-wrap {
        padding-top: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
    }
}

@media (max-width: 767px) {
    body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-xl,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-xl,body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-desktop,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-desktop,body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-desktop-medium,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-desktop-medium,body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-large,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-large,body.fl-builder-ie-11 .fl-row.fl-row-full-height.fl-visible-large-medium,body.fl-builder-ie-11 .fl-row.fl-row-custom-height.fl-visible-large-medium {
        display: none !important;
    }

    .fl-visible-xl,.fl-visible-desktop,.fl-visible-desktop-medium,.fl-visible-large,.fl-visible-large-medium,.fl-visible-medium,.fl-col-group .fl-visible-xl.fl-col,.fl-col-group .fl-visible-desktop.fl-col,.fl-col-group .fl-visible-desktop-medium.fl-col,.fl-col-group .fl-visible-large-medium.fl-col,.fl-col-group .fl-visible-medium.fl-col,.fl-col-group-equal-height .fl-visible-xl.fl-col,.fl-col-group-equal-height .fl-visible-desktop.fl-col,.fl-col-group-equal-height .fl-visible-desktop-medium.fl-col,.fl-col-group-equal-height .fl-visible-large-medium.fl-col,.fl-col-group-equal-height .fl-visible-medium.fl-col {
        display: none;
    }

    .fl-visible-medium-mobile,.fl-visible-mobile,.fl-col-group .fl-visible-medium-mobile.fl-col,.fl-col-group .fl-visible-mobile.fl-col {
        display: block;
    }

    .fl-row-content-wrap {
        background-attachment: scroll !important;
    }

    .fl-row-bg-parallax .fl-row-content-wrap {
        background-attachment: scroll !important;
        background-position: center center !important;
    }

    .fl-col-group.fl-col-group-equal-height {
        display: block;
    }

    .fl-col-group.fl-col-group-equal-height.fl-col-group-custom-width {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .fl-col-group.fl-col-group-responsive-reversed {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap-reverse;
        -webkit-flex-wrap: wrap-reverse;
        -ms-box-orient: horizontal;
    }

    .fl-col-group.fl-col-group-responsive-reversed .fl-col {
        -webkit-box-flex: 0 0 100%;
        -moz-box-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        min-width: 0;
    }

    .fl-col {
        clear: both;
        float: none;
        margin-left: auto;
        margin-right: auto;
        width: auto !important;
    }

    .fl-col-small:not(.fl-col-small-full-width) {
        max-width: 400px;
    }

    .fl-block-col-resize {
        display: none;
    }

    .fl-row[data-node] .fl-row-content-wrap {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .fl-row[data-node] .fl-bg-video,.fl-row[data-node] .fl-bg-slideshow {
        left: 0;
        right: 0;
    }

    .fl-col[data-node] .fl-col-content {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .fl-row[data-node] > .fl-row-content-wrap {
        padding-top: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
    }
}

.page .fl-post-header, .single-fl-builder-template .fl-post-header {
    display: none;
}

.fl-node-5nhrxv7mldz4.fl-row-full-height > .fl-row-content-wrap,.fl-node-5nhrxv7mldz4.fl-row-custom-height > .fl-row-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

/* .fl-node-5nhrxv7mldz4.fl-row-full-height > .fl-row-content-wrap {
    min-height: 100vh;
}

.fl-node-5nhrxv7mldz4.fl-row-custom-height > .fl-row-content-wrap {
    min-height: 0;
} */

/* .fl-builder-edit .fl-node-5nhrxv7mldz4.fl-row-full-height > .fl-row-content-wrap {
    min-height: calc( 100vh - 48px );
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .fl-node-5nhrxv7mldz4.fl-row-full-height > .fl-row-content-wrap {
        min-height: 1024px;
    }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .fl-node-5nhrxv7mldz4.fl-row-full-height > .fl-row-content-wrap {
        min-height: 768px;
    }
} */

/* @media screen and (device-aspect-ratio: 40/71) {
    .fl-node-5nhrxv7mldz4.fl-row-full-height > .fl-row-content-wrap {
        min-height: 500px;
    }
} */

/* .fl-node-5nhrxv7mldz4 > .fl-row-content-wrap {
    background-color: rgb(98, 182, 208);
} */

/* .fl-node-5nhrxv7mldz4.fl-row-custom-height > .fl-row-content-wrap {
    min-height: 500px;
} */

/* .fl-node-5nhrxv7mldz4 .fl-row-content {
    max-width: 95vw;
} */

.fl-node-5nhrxv7mldz4 .fl-builder-bottom-edge-layer > * {
    width: 100%;
    left: auto;
    right: auto;
    height: auto;
    top: auto;
    bottom: 0;
    /* transform: scaleX(1) scaleY(1); */
}

.fl-node-5nhrxv7mldz4 .fl-builder-bottom-edge-layer .fl-shape-content .fl-shape {
    fill: #aaa;
}

.fl-node-5nhrxv7mldz4 > .fl-row-content-wrap {
    padding-top: 50px;
    /* padding-bottom: 20vh; */
    padding-bottom: 10vh;

}

@media (max-width: 900px) {
    .fl-node-5nhrxv7mldz4 > .fl-row-content-wrap {
        padding-top: 50px;
        /* padding-bottom: 15vh; */
    }
}


.fl-node-xdy7g6jielbc > .fl-row-content-wrap {
    background-color: #ffffff;
}

.fl-node-xdy7g6jielbc.fl-row-fixed-width, .fl-node-xdy7g6jielbc .fl-row-fixed-width {
    max-width: 1600px;
}

.fl-node-hp4jetucyodm > .fl-row-content-wrap {
    background-color: #ffffff;
}

.fl-node-hp4jetucyodm .fl-row-content {
    max-width: 800px;
}

.fl-node-fmb3zdgwlt0k > .fl-row-content-wrap {
    background-color: #ffffff;
}

.fl-node-fmb3zdgwlt0k.fl-row-fixed-width, .fl-node-fmb3zdgwlt0k .fl-row-fixed-width {
    max-width: 800px;
}

.fl-node-fmb3zdgwlt0k > .fl-row-content-wrap {
    margin-top: 32px;
    margin-bottom: 32px;
}

.fl-node-8z7dbwpkhtmo > .fl-row-content-wrap {
    background-color: #ffffff;
}

.fl-node-8z7dbwpkhtmo .fl-row-content {
    max-width: 800px;
}

.fl-node-8z7dbwpkhtmo > .fl-row-content-wrap {
    margin-top: 48px;
    margin-bottom: 48px;
}

.fl-node-u7gho0tz4rwc > .fl-row-content-wrap {
    background-color: #c9e3dc;
}

.fl-node-u7gho0tz4rwc .fl-row-content {
    max-width: 800px;
}

.fl-node-u7gho0tz4rwc .fl-builder-top-edge-layer > * {
    width: 100%;
    left: auto;
    right: auto;
    height: auto;
    top: 0;
    bottom: auto;
    transform: scaleX(1) scaleY(1);
}

.fl-node-u7gho0tz4rwc .fl-builder-top-edge-layer .fl-shape-content .fl-shape {
    fill: #ffffff;
}

.fl-node-u7gho0tz4rwc > .fl-row-content-wrap {
    padding-top: 192px;
    padding-bottom: 96px;
}

.fl-node-5pzxeh48s6fl {
    width: 100%;
}

.fl-node-le76ushj13dg {
    width: 100%;
}

.fl-node-oxkdjq60um5r {
    width: 100%;
}

.fl-node-tseya8h7ugnf {
    width: 100%;
}

.fl-node-dyf3aiertq0s {
    width: 100%;
}

.fl-node-fblxm5wg6phd {
    width: 16.65%;
}

.fl-node-flnrhdpt16cg {
    width: 16.65%;
}

.fl-node-jywulqtf906v {
    width: 16.65%;
}

.fl-node-pg6rmc2f7k90 {
    width: 16.65%;
}

.fl-node-18safx7q9w5m {
    width: 16.65%;
}

.fl-node-dx6mnqczalhj {
    width: 16.65%;
}

.fl-node-kadlhi8jt35w {
    width: 100%;
}



/* .fl-row .fl-col .fl-node-m0ohvpa9fc2t h2.fourh-heading .fourh-heading-text {
    color: #000000;
} */

/* .fl-row .fl-col .fl-node-m0ohvpa9fc2t h2.fourh-heading .fourh-heading-text .slant {
    background-color: #F54359;
} */

.fl-row .fl-col .fl-node-m0ohvpa9fc2t h2.fourh-heading .fourh-heading-text .slant {
    color: #ffffff;
}

.fl-node-m0ohvpa9fc2t.fl-module-4h-heading-slant .fourh-heading {
    font-size: 5rem;
    line-height:  7rem;
    padding-top: 20px;
}

@media(max-width: 900px) {
    .fl-node-m0ohvpa9fc2t.fl-module-4h-heading-slant .fourh-heading {
        font-size: 3rem;
       line-height: 5rem;
        text-align: left;
    }
}

@media(max-width: 600px) {
    .fl-node-m0ohvpa9fc2t.fl-module-4h-heading-slant .fourh-heading {
        font-size: 1.8rem;
       line-height: 1.8rem;
        text-align: left;
    }
}
@media(max-width: 360px) {
    .fl-node-m0ohvpa9fc2t.fl-module-4h-heading-slant .fourh-heading {
        font-size: 1.7rem;
       line-height: 1.7rem;
        text-align: left;
    }
}
@media(max-width: 300px) {
    .fl-node-m0ohvpa9fc2t.fl-module-4h-heading-slant .fourh-heading {
        font-size: 1.5rem;
       line-height: 1.5rem;
        text-align: left;
    }
}
/* @media(max-width: 767px) {
    .fl-node-m0ohvpa9fc2t.fl-module-4h-heading-slant .fourh-heading {
        font-size: 1.6rem;
       line-height: 1.6rem;
        text-align: left;
    }
} */
/* 
.fl-node-m0ohvpa9fc2t > .fl-module-content {
    margin-top: -60px;
}

@media ( max-width: 1100px ) {
    .fl-node-m0ohvpa9fc2t.fl-module > .fl-module-content {
        margin-top:-20px;
    }
} */

/* @media (max-width: 790px) {
    .fl-node-m0ohvpa9fc2t > .fl-module-content {
        margin-top:20px;
    }
} */

.fl-module-heading .fl-heading {
    padding: 0 !important;
    margin: 0 !important;
}

.fl-builder-content .fl-rich-text strong {
    font-weight: bold;
}

.fl-node-zgbewrfm9yci.fl-module-heading .fl-heading {
    font-size: 32px;
    line-height: 40px;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-item {
    margin-bottom: 10px;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-buttonb2zudepnym76 {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    background: #ffffff;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-buttonb2zudepnym76 .uabb-adv-accordion-button-icon {
    color: #349966;
    -webkit-transition: color 15ms linear, transform 60ms linear;
    -moz-transition: color 15ms linear, transform 60ms linear;
    -o-transition: color 15ms linear, transform 60ms linear;
    transition: color 15ms linear, transform 60ms linear;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-contentb2zudepnym76 {
    background: #F9FAFB;
    padding-top: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-buttonb2zudepnym76:hover .uabb-adv-accordion-button-icon,.fl-node-b2zudepnym76 .uabb-adv-accordion-item-active > .uabb-adv-accordion-buttonb2zudepnym76 .uabb-adv-accordion-button-icon {
    color: #349966;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-buttonb2zudepnym76 .uabb-adv-accordion-button-icon,.fl-node-b2zudepnym76 .uabb-adv-accordion-buttonb2zudepnym76 .uabb-adv-accordion-button-icon.dashicons:before {
    font-size: 26px;
    line-height: 28px;
    height: 28px;
    width: 28px;
}


.fl-node-b2zudepnym76 .uabb-adv-accordion-buttonb2zudepnym76 {
    border-style: solid;
    border-width: 0;
    background-clip: border-box;
    border-color: #BDBDBD;
    border-bottom-width: 1px;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-contentb2zudepnym76 {
    border-style: none;
    border-width: 0;
    background-clip: border-box;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-buttonb2zudepnym76 .uabb-adv-accordion-button-label {
    font-family: "4H Gilroy", Arial, sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
}

.fl-node-b2zudepnym76 .uabb-adv-accordion-contentb2zudepnym76 {
    font-family: "4H Gilroy", Arial, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.4px;
}

/* .fl-row .fl-col .fl-node-12zipbj94xay h3.fl-heading a,.fl-row .fl-col .fl-node-12zipbj94xay h3.fl-heading .fl-heading-text,.fl-row .fl-col .fl-node-12zipbj94xay h3.fl-heading .fl-heading-text *,.fl-node-12zipbj94xay h3.fl-heading .fl-heading-text {
    color: #000000;
} */

.fl-node-12zipbj94xay.fl-module-heading .fl-heading {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.6px;
}

.fl-node-12zipbj94xay > .fl-module-content {
    margin-top: 20px;
    margin-bottom: 5px;
}

@media (max-width: 767px) {
    .fl-node-12zipbj94xay > .fl-module-content {
        margin-top:0px;
        margin-bottom: 0px;
    }
}

.uabb-photo-content.uabb-img-grayscale img {
    -webkit-filter: grayscale(100%);
    -webkit-filter: grayscale(1);
    filter: grayscale(100%);
    filter: gray;
}

.uabb-photo-content .uabb-photo-caption {
    pointer-events: none;
}

.uabb-photo-img {
    max-height: inherit;
}

.fl-node-u4ja7moseqrw {
    width: 100%;
}

.fl-node-u4ja7moseqrw .uabb-photo-content {
    background-color: #ffffff;
    border-radius: 32px;
}

.fl-node-u4ja7moseqrw .uabb-photo-content img {
    border-radius: 32px;
}

.fl-node-u4ja7moseqrw .uabb-photo-content .uabb-photo-img {
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.fl-node-u4ja7moseqrw .uabb-photo-content .uabb-photo-img {
    opacity: 1;
}

.fl-node-u4ja7moseqrw .uabb-photo-content .uabb-photo-img:hover {
    opacity: 1;
}

@media ( max-width: 767px ) {
    .fl-node-u4ja7moseqrw .uabb-photo-mob-align-center {
        text-align: center;
    }
}

.fl-node-u4ja7moseqrw > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media ( max-width: 767px ) {
    .fl-node-u4ja7moseqrw.fl-module > .fl-module-content {
        margin-top:10px;
        margin-bottom: 10px;
    }
}

.fl-node-knujxq8cadh1 {
    width: 100%;
}

.fl-node-knujxq8cadh1 .uabb-photo-content {
    background-color: #ffffff;
    border-radius: 32px;
}

.fl-node-knujxq8cadh1 .uabb-photo-content img {
    border-radius: 32px;
}

.fl-node-knujxq8cadh1 .uabb-photo-content .uabb-photo-img {
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.fl-node-knujxq8cadh1 .uabb-photo-content .uabb-photo-img {
    opacity: 1;
}

.fl-node-knujxq8cadh1 .uabb-photo-content .uabb-photo-img:hover {
    opacity: 1;
}

@media ( max-width: 767px ) {
    .fl-node-knujxq8cadh1 .uabb-photo-mob-align-center {
        text-align: center;
    }
}

.fl-node-knujxq8cadh1 > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media ( max-width: 767px ) {
    .fl-node-knujxq8cadh1.fl-module > .fl-module-content {
        margin-top:10px;
        margin-bottom: 10px;
    }
}

.fl-node-t1onvl2x8w9z {
    width: 100%;
}

.fl-node-t1onvl2x8w9z .uabb-photo-content {
    background-color: #ffffff;
    border-radius: 32px;
}

.fl-node-t1onvl2x8w9z .uabb-photo-content img {
    border-radius: 32px;
}


.fl-node-t1onvl2x8w9z .uabb-photo-content .uabb-photo-img {
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.fl-node-t1onvl2x8w9z .uabb-photo-content .uabb-photo-img {
    opacity: 1;
}

.fl-node-t1onvl2x8w9z .uabb-photo-content .uabb-photo-img:hover {
    opacity: 1;
}

@media ( max-width: 767px ) {
    .fl-node-t1onvl2x8w9z .uabb-photo-mob-align-center {
        text-align: center;
    }
}

.fl-node-t1onvl2x8w9z > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media ( max-width: 767px ) {
    .fl-node-t1onvl2x8w9z.fl-module > .fl-module-content {
        margin-top:10px;
        margin-bottom: 10px;
    }
}

.fl-node-r5z7qd39lj8u {
    width: 100%;
}

.fl-node-r5z7qd39lj8u .uabb-photo-content {
    background-color: #ffffff;
    border-radius: 32px;
}

.fl-node-r5z7qd39lj8u .uabb-photo-content img {
    border-radius: 32px;
}

.fl-node-r5z7qd39lj8u .uabb-photo-content .uabb-photo-img {
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.fl-node-r5z7qd39lj8u .uabb-photo-content .uabb-photo-img {
    opacity: 1;
}

.fl-node-r5z7qd39lj8u .uabb-photo-content .uabb-photo-img:hover {
    opacity: 1;
}

@media ( max-width: 767px ) {
    .fl-node-r5z7qd39lj8u .uabb-photo-mob-align-center {
        text-align: center;
    }
}

.fl-node-r5z7qd39lj8u > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media ( max-width: 767px ) {
    .fl-node-r5z7qd39lj8u.fl-module > .fl-module-content {
        margin-top:10px;
        margin-bottom: 10px;
    }
}

.fl-node-gl0p1x7bqku3 {
    width: 100%;
}

.fl-node-gl0p1x7bqku3 .uabb-photo-content {
    background-color: #ffffff;
    border-radius: 32px;
}

.fl-node-gl0p1x7bqku3 .uabb-photo-content img {
    border-radius: 32px;
}

.fl-node-gl0p1x7bqku3 .uabb-photo-content .uabb-photo-img {
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.fl-node-gl0p1x7bqku3 .uabb-photo-content .uabb-photo-img {
    opacity: 1;
}

.fl-node-gl0p1x7bqku3 .uabb-photo-content .uabb-photo-img:hover {
    opacity: 1;
}

@media ( max-width: 767px ) {
    .fl-node-gl0p1x7bqku3 .uabb-photo-mob-align-center {
        text-align: center;
    }
}

.fl-node-gl0p1x7bqku3 > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media ( max-width: 767px ) {
    .fl-node-gl0p1x7bqku3.fl-module > .fl-module-content {
        margin-top:10px;
        margin-bottom: 10px;
    }
}

.fl-node-ms3hrknw2bx9 {
    width: 100%;
}

.fl-node-ms3hrknw2bx9 .uabb-photo-content {
    background-color: #ffffff;
    border-radius: 32px;
}

.fl-node-ms3hrknw2bx9 .uabb-photo-content img {
    border-radius: 32px;
}

.fl-node-ms3hrknw2bx9 .uabb-photo-content .uabb-photo-img {
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.fl-node-ms3hrknw2bx9 .uabb-photo-content .uabb-photo-img {
    opacity: 1;
}

.fl-node-ms3hrknw2bx9 .uabb-photo-content .uabb-photo-img:hover {
    opacity: 1;
}

@media ( max-width: 767px ) {
    .fl-node-ms3hrknw2bx9 .uabb-photo-mob-align-center {
        text-align: center;
    }
}

.fl-node-ms3hrknw2bx9 > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media ( max-width: 767px ) {
    .fl-node-ms3hrknw2bx9.fl-module > .fl-module-content {
        margin-top:10px;
        margin-bottom: 10px;
    }
}

.css-1g7ucmu-ProductImageSoldOutLabelCSS {
    z-index: 1;
}

.css-1sxijs2-ProductImageOnSaleLabelCSS {
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .fl-row-content-wrap:not(.hero) svg.fl-builder-layer-align-bottom-center,.fl-row-content-wrap:not(.hero) svg.fl-builder-layer-align-top-center {
        transform: scaleX(0) scaleY(0)!important;
    }
}

.fl-col-group-equal-height.fl-col-group-align-bottom .fl-col-content {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
}

.uabb-module-content h1,.uabb-module-content h2,.uabb-module-content h3,.uabb-module-content h4,.uabb-module-content h5,.uabb-module-content h6 {
    margin: 0;
    clear: both;
}

.fl-module-content a,.fl-module-content a:hover,.fl-module-content a:focus {
    text-decoration: none;
}

.fl-builder-content-editing .fl-visible-medium.uabb-row,.fl-builder-content-editing .fl-visible-medium-mobile.uabb-row,.fl-builder-content-editing .fl-visible-mobile.uabb-row {
    display: none !important;
}

@media (max-width: 992px) {
    .fl-builder-content-editing .fl-visible-desktop.uabb-row,.fl-builder-content-editing .fl-visible-mobile.uabb-row {
        display: none !important;
    }

    .fl-builder-content-editing .fl-visible-desktop-medium.uabb-row,.fl-builder-content-editing .fl-visible-medium.uabb-row,.fl-builder-content-editing .fl-visible-medium-mobile.uabb-row {
        display: block !important;
    }
}

@media (max-width: 768px) {
    .fl-builder-content-editing .fl-visible-desktop.uabb-row,.fl-builder-content-editing .fl-visible-desktop-medium.uabb-row,.fl-builder-content-editing .fl-visible-medium.uabb-row {
        display: none !important;
    }

    .fl-builder-content-editing .fl-visible-medium-mobile.uabb-row,.fl-builder-content-editing .fl-visible-mobile.uabb-row {
        display: block !important;
    }
}

.fl-responsive-preview-content .fl-builder-content-editing {
    overflow-x: hidden;
    overflow-y: visible;
}

/* .fl-row .fl-col .fl-node-42al3gzjmn16 .fourh-subheading-text {
    color: #000000;
} */

.fl-node-42al3gzjmn16.fl-module-4h-subheading .fourh-subheading-text {
    text-align: left;
    margin-bottom: 15px;
}


.fl-row-content-wrap.hero .subheading {
    margin-left: auto!important;
    margin-right: auto!important;
    /* max-width: 80%; */
    padding-left:7.14vw;
    padding-right:23.14vw;
}

@media (max-width: 698px) {
    .fl-row-content-wrap.hero .subheading {
     
        /* padding-right:14.14vw; */
    }
}

.fl-row-content-wrap.hero .subheading2 {
    margin-left: auto!important;
    margin-right: auto!important;
    /* max-width: 80%; */
    padding-left:7.14vw;
    padding-right:34.14vw;
}

@media (max-width: 698px) {
    .fl-row-content-wrap.hero .subheading2 {
     
        padding-right:34.14vw;
    }
}

.fl-row-content-wrap.hero .subheading2 {
    margin-left: 0!important;
    margin-right: auto!important;
    /* max-width: 51rem; */
    /* width: 75%; */
}
.fl-node-42al3gzjmn16.fl-module-4h-subheading .fourh-subheading-text {
    text-align: left;
}


@media screen and (min-width: 768px) {
    .fl-row-content-wrap.hero .subheading {
        margin-left: 0!important;
        margin-right: 0!important;
        width: auto;
    }
}

/* @media screen and (min-width: 1280px) {
    .fl-row-content-wrap.hero .subheading {
        max-width: 60vw;
    }
} */


.fl-row .subheading {
    display: block;
    font-family: "Changa";
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.7rem;
}

.subheading2 {
    display: block;
    color: rgb(98, 182, 208);
    /* color:rgb(202, 243, 255); */
    font-family: "Changa";
    font-size: 1.7rem;
    font-weight: 700;
    /* padding-top: 10px; */
    line-height: 1.7rem;
}

@media screen and (max-width: 1280px) {
    .subheading {
        font-size: 3.2rem;
        line-height: 3.4rem;
    }
}

@media screen and (max-width: 900px) {
    .fl-row .subheading {
        font-size: 20px !important;
        line-height:20px !important;
    }
}

@media screen and (max-width: 900px) {
    .subheading2 {
        font-size: 20px;
        line-height:20px;
    }
}

.fl-node-1lgzymu2wqjf.fl-module-4h-heading-slant .fourh-heading {
    text-align: left;
}

.fl-row-content-wrap.hero h1 {
    margin-bottom: 1rem;
    /* margin-top: 2rem; */
}

@media screen and (min-width: 768px) {
    .fl-row h1 {
        font-size: 5.2rem;
        line-height: 5.4rem;
    }
}

@media screen and (min-width: 1280px) {
    .fl-row h1 {
        font-size: 6.4rem;
        line-height: 6.6rem;
        margin-bottom: 5rem;
    }
}

.fl-row h1 {
    font-family: "Changa";
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.2rem;
    margin-bottom: 3.2rem;
    margin-left: auto!important;
    margin-right: auto!important;
    text-transform: uppercase;
}

.fl-row .fl-col .fl-node-m0ohvpa9fc2t h1.fourh-heading .fourh-heading-text .slant {
    color: #ffffff;
}

/* .fl-row .fl-col .fl-node-m0ohvpa9fc2t h1.fourh-heading .fourh-heading-text .slant {
    background-color: #F1B9D0;
} */

.fl-row h1 .slant {
    display: inline-block;
    padding: 0 0.5em;
    margin-left: 25px;
    transform: rotate(-4deg) translateY(0.1em);
    transform-origin: left bottom;
    white-space: nowrap;
}
@media screen and (max-width: 900px) {
    .fl-row h1 .slant {
        margin-left: 15px;
    }
}

@media screen and (max-width: 600px) {
    .fl-row h1 .slant {
        margin-left: 10px;
    }
}

/* .cl-row {
    margin-left: auto;
    margin-right: auto;
    min-width: 0;
}

.cl-n.cl-row-height > .cl-content-row-wrap {
    min-height: 600px;
}

.cl-n.cl-row-height > .cl-content-row-wrap {
    display: flex;
}

.cl-content-row-wrap.hero {
    position: relative;
}

.cl-n > .cl-content-row-wrap {
    padding-top: 150px;
    padding-bottom: 200px;
}

.cl-n > .cl-content-row-wrap {
    background-color: #62B6D0;
}

.cl-row-height .cl-content-row-wrap {
    min-height: 0;
}

.cl-content-row-wrap { 
    padding: 32px;
}

.cl-content-row-wrap {
    margin: 0px;
}

.cl-content-row-wrap {
    position: relative;
}

.cl-shape-layer.cl-bottom-edge {
    z-index: 1;
}

.cl-shape-layer {
    z-index: 0;
}

.cl-shape-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
}

@media (max-width: 1100px) {
    .cl-n > .cl-content-row-wrap {
        padding-top: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
    }
} */