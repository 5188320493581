/* g{
  padding: 5px 10px;
}
g:hover {
  transform: rotate(45deg);
  transition: all 0.25s ease-in-out;
  /* animation: spin 1s linear; 
} */

.transform {
  transition: transform .35s ease-in !important;
}

.transform > g {
  transition: fill 1s ease-in;
}

.transform:hover {
  transform: rotate(45deg);
}


.transform-close {
  transition: transform .35s ease-in !important;
}

.transform-close > g {
  transition: fill 1s ease-in;
}

.transform-close:hover {
  transform: rotate(-45deg);
}

.link:hover{
  color: rgb(124, 144, 246) !important;
}

.link{
  text-decoration: none !important;
  color: #fff;
}