/* .slider-wrapper{
  max-height:500px !important;
} */

.partnerSwiper{
  /* width: auto !important; */
  padding: 0px 7.14vw;
}

.partnerWrapper {
  padding: 10px 20px;
  margin:0;
  width: auto !important;
  justify-content: space-between;

}

.partnerSlide{
  width: auto !important;

}

.venueWrapper {
    height: 40vh;
    border-radius: 30%;

  }


.mySwiper {
  max-width: 40vw;
}
@media screen and (max-width: 900px) {
  .swiper {
    max-width: 100% !important;
  }
  .venueWrapper {
    height: 35vh;
    padding: 0 0 2em;
  }
}

.thumb {
  height: 60px;
}
.thumb img {
  height: 100%;
  width: 100%;
}

.slide {
  max-height: 500px;
  border-radius: 30%;

}

.legend {
  left: 50% !important;
  right: auto !important;
  margin-left: -25% !important;
  width: 50% !important;
}

.center-con {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.round {
  position: absolute;
  border: 2px solid #62b6d0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

#cta {
  width: 100%;
  cursor: pointer;
  position: absolute;
  color: #62b6d0;
}

#cta .arrow {
  left: 30%;
}
.arrow {
  position: absolute;
  bottom: 0;
  margin-left: 0px;
  width: 12px;
  height: 12px;
  background-size: contain;
  top: 15px;
}
.segunda {
  margin-left: 8px;
}
.next {
  background-color: #62b6d0;
  mask-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}

.prev {
  /* transform: rotateY(180deg); */
  background-color: #62b6d0;
  mask-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}
@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.arrow.primera.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.round:hover .arrow {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.round:hover .arrow.primera {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
